import { useState } from "react";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import "./App.css";
import Snowfield from "./Snowfield";
const catsJSON = require("./cats.json");

function shuffleArray(arr) {
	for (let i = arr.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[arr[i], arr[j]] = [arr[j], arr[i]];
	}
}

function App() {
	const [urls] = useState(() => {
		const { photos } = catsJSON;
		shuffleArray(photos);
		return photos.map(
			(x) =>
				`https://rammtbvse4.execute-api.us-east-1.amazonaws.com/prod/compressJPEGPhotoFromURL?url=${x}`
		);
	});

	let setPhotoAndFooterVisiblity = (visible) => {
		const footer = document.querySelector(".viewer-footer");
		const canvas = document.querySelector(".viewer-canvas");
		if (visible) {
			footer.style = "";
			canvas.style = "";
		} else {
			footer.style = "opacity: 0";
			canvas.style = "opacity: 0";
		}
	};
	return (
		<div className="app">
			<Snowfield style={{ width: "100vw", height: "100vh" }} />
			<RViewerJS
				className="hidden"
				options={{
					inline: true,
					backdrop: false,
					button: false,
					title: false,
					toolbar: {
						prev: "large",
						next: "large",
					},
					fullscreen: false,
					interval: 5000,
					url: function (img) {
						return img.src.substr(img.src.lastIndexOf("?url=") + 5);
					},
					play: () => setPhotoAndFooterVisiblity(false),
					stop: () => setPhotoAndFooterVisiblity(true),
				}}
			>
				{urls.map((x) => (
					<img src={x} key={x} alt="Soup or Bebo" loading="lazy" />
				))}
			</RViewerJS>
		</div>
	);
}

export default App;
